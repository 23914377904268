import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { PermissionApp } from '@emrm/core/permissions/types';
import { PermissionsStoreService } from '@emrm/core/permissions/service';

export const PermissionResolver: ResolveFn<PermissionApp[]> = () => {
	const permissionsStoreService: PermissionsStoreService = inject(
		PermissionsStoreService,
	);

	return permissionsStoreService.load();
};
