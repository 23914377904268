import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Settings } from '@emrm/settings/types';

import { SettingsStoreService } from './settings-store.service';

export const SettingsResolver: ResolveFn<Settings> = () => {
	const service: SettingsStoreService = inject(SettingsStoreService);

	return service.load();
};
